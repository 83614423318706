import React from 'react'
import Desktop from "./Desktop"
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'

const Web = () => {
  return (
    <>
    <Canvas style={{ width: '90%'}}>
        <Stage environment="" intensity={0.6}>
        <Desktop/>
        </Stage>
        <OrbitControls enableZoom={false} autoRotate/>
        
    </Canvas>

<div className='des web2'>
<span className='desText'>We provide web scraping services to extract valuable data from websites for better decision-making and analysis..</span>
</div>
</>
  )
}

export default Web