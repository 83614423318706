import React, { useState } from 'react'
import "./Contact.scss"
import axios from 'axios'
 

const Contact = () => {

const [name,setName] = useState('')
const [email,setEmail] = useState('')
const [message,setMessage] = useState('')

const handleclick = async()=> {

  const data = {
    name:name,
    email:email,
    message:message
  }

  try {
    const send = await axios.post("https://sheet.best/api/sheets/c9312987-7394-4adc-878c-3d8d72256f52",data)
    console.log(send);
    setName('')
    setEmail('')
    setMessage('')
    window.location.reload()
  } catch (error) {
    console.log(error)
    
  }

}




  return (
  <section className='contact_section'>
    
    <div className='contact'>
     
     <h1 className='contactus_tittle'>Contact us</h1>
    
    <div className='contact_left'>
    <form className='contact_form'>
      <input type='text' placeholder='Name' className='contact_input' onChange={(e)=>setName(e.target.value)}></input>
      <input type='email' placeholder='Email' className='contact_input' onChange={(e)=>setEmail(e.target.value)}></input>
      <textarea className='contact_input' rows={10} placeholder='Enter a Message' onChange={(e)=>setMessage(e.target.value)}></textarea>
      <button className='contact_button' onClick={handleclick}>Send</button>
    </form>
    </div>

    <div className="contact_right">
     <img className='contact_image' src='/img/contact.png'></img>
    </div>

   </div>
    
    </section>
  )
}

export default Contact