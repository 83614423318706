import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Robot from "./Robot"

const Automation = () => {
  return (
    <>
    <Canvas style={{ width: '100%'}}>
        <Stage environment="" intensity={0.6}>
      <Robot/>
        </Stage>
        <OrbitControls enableZoom={false} autoRotate/>
        
    </Canvas>

<div className='des ai'>
<span className='desText'>We leverage AI and machine learning to develop intelligent solutions that drive business growth and improve efficiency.</span>
</div>
</>
  )
}

export default Automation