/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 mobile2.gltf --transform
Author: Fred Drabble (https://sketchfab.com/FredDrabble)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/iphone-x-lowpoly-3067c70fb7d14bd3b2b03e3246d6052d
Title: IPHONE X LOWPOLY
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('./img/mobile2-transformed.glb')

  const scale = 0.7; // Change the scale factor as desired

  return (
    <group {...props} dispose={null} scale={[scale, scale, scale]}>
      <mesh geometry={nodes['phone_01_-_Default_0'].geometry} material={materials.PaletteMaterial001} position={[0.093, 0, -3.85]} rotation={[0, 0, Math.PI / 2]} />
      <mesh geometry={nodes['lens_14_-_Default_0'].geometry} material={materials.PaletteMaterial002} position={[23.546, 53.065, -0.073]} rotation={[Math.PI, 0, -Math.PI]} scale={[0.937, 1, 1]} />
      <mesh geometry={nodes['Sphere001_08_-_Default_0'].geometry} material={materials.PaletteMaterial003} position={[23.764, 59.922, -4.507]} rotation={[Math.PI, 0, 0]} scale={[0.141, 0.141, 0.046]} />
    </group>
  )
}

useGLTF.preload('/mobile2-transformed.glb')
