import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Pos from "./Pos"

const MobileScreen = () => {
  return (
    <>
    <Canvas style={{ width: '80%'}}>
        <Stage environment="" intensity={0.6}>
       <Pos/>
        </Stage>
        <OrbitControls enableZoom={false} autoRotate/>
        
    </Canvas>

<div className='des crm'>
<span className='desText'>We specialize in creating powerful and efficient POS, CRM, billing, and ERP solutions for seamless business operations.</span>
</div>
</>
  )
}

export default MobileScreen