/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 desktop.gltf --transform
Author: W0nnaFight (https://sketchfab.com/WannaFight)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/desktop-pc-low-poly-game-ready-29fe58f2082f4b0d88a20947df307020
Title: Desktop PC - Low Poly / Game Ready
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('./img/desktop-transformed.glb')

  const scale = 0.9; // Change the scale factor as desired


  return (
    <group {...props} dispose={null} scale={[scale, scale, scale]}>
      <mesh geometry={nodes.key_low__0.geometry} material={materials['Scene_-_Root']} position={[0.633, 0, 3.336]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]} />
    </group>
  )
}

useGLTF.preload('/desktop-transformed.glb')
