import React from 'react'
import "./Aboutus.scss"
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Text } from '@react-three/drei';





const Aboutus = () => {
  return (
    <>
    

    <section className='about_section'>
      
  
      <div className='about'>
     
      <h1 className='aboutus_tittle'>About us</h1>
     
     <div className='about_left'>
      <span className='about_subtittle'>We build your bussiness</span>
     <p className='about_desc'>
     We, The Connecting Dotts, have the vision to enhance and solve problems with creative solutions. We are here to smoothen the process of work culture while enhancing technology. We develop web applications and websites that are dynamic in nature, solely focused on user interaction and user satisfaction. Automation is the area that makes us unique in terms of our services. Automation enables us to complete your tasks with just one click, providing ease and accuracy. Our mobile applications cover a more extensive user base and provide a seamless market flow. With DevOps, we make your business scalable and enhance customer interaction. Our expertise in web scraping allows us to provide market analysis that keeps you up to date and ahead of the curve, improving decision making. We are dedicated to developing, representing, and expanding your ideas through our creativity, flexibility, and accuracy. Our solutions include web applications, robotic process automation, mobile applications, websites, DevOps, and web scraping.
     </p>
     </div>

     <div className="about_right">
     <Canvas className='about_right_image'>
            <OrbitControls autoRotate enableZoom={false} />
            <ambientLight intensity={1} />
            <directionalLight position={[2, 3, 1, 4]} />
            <mesh>
              <capsuleGeometry args={[1, 1, 1]} />
              <meshPhongMaterial color="pink" wireframe={true} />
              
            </mesh>
            <Text position={[0, 0, 0]}  fontSize={0.3} color="white">
                Who we are
              </Text>
          </Canvas>
     </div>

    </div>
      
      </section>
      </>
  )
}

export default Aboutus