import React from 'react'
import Header from "./Components/Header/Header"
import Aboutus from "./Components/Aboutus/Aboutus"
import Services from "./Components/Services/Services"
import Contact from "./Components/Contact/Contact"
import Footer from './Components/Footer/Footer'
import { BrowserRouter as Router } from 'react-router-dom';
import Why from './Components/Why/Why'



function App() {
  return (
    <>
<Router basename="/">
    <div className='container' style={{background:'url("./img/bg.jpeg")'}}>
    {/* <Header/>
    <Aboutus/>
    <Services/>
    <Contact/>
    <Footer/> */}

     
      <Header />
      <div id="about">
        <Aboutus />
      </div>
      <Why/>
      <div id="services">
        <Services />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </div>


    </Router>
    </>
  );
}

export default App;
