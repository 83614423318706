import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Html from "./Html"

const Web2 = () => {
  return (
    <>
    <Canvas style={{ width: '80%'}}>
        <Stage environment="" intensity={0.6}>
        <Html/>
        </Stage>
        <OrbitControls enableZoom={false} autoRotate/>
    </Canvas>

    <div className='des web'>
     <span className='desText'>We specialize in web development services. With our expertise and dedication team.</span>
    </div>
    </>
  )
}

export default Web2