/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 robot.gltf --transform
Author: DeadLink (https://sketchfab.com/hell3879)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/smart-littel-robot-dd3ae79fb02944dd94a15826647d014a
Title: Smart Littel Robot
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./img/robot-transformed.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <primitive object={nodes._rootJoint} />
        <skinnedMesh name="Object_6" geometry={nodes.Object_6.geometry} material={materials.Body_lambert} skeleton={nodes.Object_6.skeleton} scale={1.686} />
        <skinnedMesh name="Object_8" geometry={nodes.Object_8.geometry} material={materials.Hand1_lambert} skeleton={nodes.Object_8.skeleton} scale={1.686} />
        <skinnedMesh name="Object_10" geometry={nodes.Object_10.geometry} material={materials.Hand_lambert} skeleton={nodes.Object_10.skeleton} scale={1.686} />
        <skinnedMesh name="Object_11" geometry={nodes.Object_11.geometry} material={materials.Legs_lambert} skeleton={nodes.Object_11.skeleton} scale={1.686} />
        <skinnedMesh name="Object_12" geometry={nodes.Object_12.geometry} material={materials.Legs1_lambert} skeleton={nodes.Object_12.skeleton} scale={1.686} />
        <skinnedMesh name="Object_13" geometry={nodes.Object_13.geometry} material={materials.Head_lambert} skeleton={nodes.Object_13.skeleton} scale={1.686} />
      </group>
    </group>
  )
}

useGLTF.preload('/robot-transformed.glb')
