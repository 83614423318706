import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Mobile2 from "./Mobile2"

const MobileScreen = () => {
  return (
    <>
    <Canvas style={{ width: '80%'}}>
        <Stage environment="" intensity={0.6}>
       <Mobile2/>
        </Stage>
        <OrbitControls enableZoom={false} autoRotate/>
        
    </Canvas>

<div className='des mobile'>
<span className='desText'>We create innovative mobile apps that enhance user engagement and provide seamless digital experiences.</span>
</div>
</>
  )
}

export default MobileScreen