import React, { useState } from 'react'
import "./Navbar.scss"
import { Link } from 'react-router-dom'
import Quotation from '../Quotation/Quotation'
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';


const Navbar = () => {

const [quote, setQuote] = useState(false)
const [opennav, setOpennav] = useState(false)


  return (
    <section className='navbar_section'>
      <div className='navbar'>
         <div className='navbar_left'>
          <div className='navbar_logo'>
            <img className='cd_logo' src='/img/cdLogo.jpg'></img>
            <span className='navbar_heading'>Connecting Dotts</span>
          </div>
         </div>

         <div className='navbar_right'>
         <ul className='navbar_menu_list' style={opennav ? { transform: 'translateX(0)' } : null}>
          <li className='navbar_menu_item'><Link to="/" className='link'>Home</Link></li>
          <li className='navbar_menu_item'> <a href="#about" className='link'>About</a></li>
          <li className='navbar_menu_item'> <a href="#services" className='link'>Services</a></li>
          <li className='navbar_menu_item'> <a href="#contact" className='link'>Contact</a></li>
          <button className='getquote' onClick={()=>setQuote(true)}>Get Quotation</button>
          <CancelIcon className='close_quote' onClick={()=>setOpennav(!opennav)}/>

         </ul>
         {/* <span className='navbar_menu'><MenuIcon className='menu_icon'/></span> */}
           <input type='checkbox' id="menubox" style={{display:'none'}}></input>
           <label htmlFor="menubox" className='navbar_menu' id="checkmenu">
           <MenuIcon className='menu_icon' onClick={()=>setOpennav(!opennav)}/>
           </label>
          </div>


      </div>
      {
      quote && <Quotation close={()=>setQuote(false)}/>
    }
    </section>

    
  )
}

export default Navbar