import React , { useState } from 'react'
import "./Quotation.scss"
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios'

const Quotation = ({close}) => {

  const [name,setName] = useState('')
  const [email,setEmail] = useState('')
  const [message,setMessage] = useState('')
  const [lastname,setLastname] = useState('')
  const [phone,setPhone] = useState('')
  
  const handleclick = async()=> {
 
    const data = {
      name:name,
      email:email,
      message:message,
      lastname:lastname,
      phone:phone
    }
  
    try {
      const send = await axios.post("https://sheet.best/api/sheets/c9312987-7394-4adc-878c-3d8d72256f52",data)
      console.log(send);
      setName('')
      setEmail('')
      setMessage('')
      setLastname('')
      setPhone('')
      window.location.reload()
    } catch (error) {
      console.log(error)
      
    }
  
  }



  return (
    <div className='Quotation'>
      <form className='quotation_form'>
       <CancelIcon className='close_form' onClick={()=>close(false)}/>
       <h1 className='quotation_heading'>Send Quotation</h1>
      <input required type="text" placeholder='Name' className='quotation_input' onChange={(e)=>setName(e.target.value)}></input>
      <input required type="text" placeholder='Lastname' className='quotation_input' onChange={(e)=>setEmail(e.target.value)}></input>
      <input type="email" placeholder='email' className='quotation_input' onChange={(e)=>setMessage(e.target.value)}></input>
      <input type="phone" placeholder='Phone' className='quotation_input' onChange={(e)=>setLastname(e.target.value)}></input>
      <textarea  rows={10} placeholder='Message' className='quotation_input' onChange={(e)=>setPhone(e.target.value)}></textarea>
      <button className='send_quote' onClick={handleclick}>Send</button>
      </form>

    </div>
  )
}

export default Quotation