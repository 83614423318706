import React from 'react'
import "./Footer.scss"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  return (
    <div className='footer'>
    
      <div className='footer_mail'>
      <EmailIcon className='footer_icon'/>
      <span className='footer_text'>info@connectingdotts.com</span>
      </div>

      <span className='footer_text'>Created By Connecting Dotts | All Rights Reserved.</span>

    
      <div className='footer_icons'>
      <FacebookIcon className='footer_icon'/>
      <InstagramIcon className='footer_icon'/>
      <YouTubeIcon className='footer_icon'/>
      </div>
    </div>
  )
}

export default Footer