import { OrbitControls, Stage } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense } from "react";
import Mac from "./Mac";



const WebDesign = () => {
  return (
    <>
      <Canvas style={{ width: '70%'}}>
        <Suspense fallback={null}>
          <Stage environment="" intensity={0.6}>
            <Mac />
          </Stage>
          <OrbitControls enableZoom={false} autoRotate />
        </Suspense>
      </Canvas>

      <div className='des automation'>
<span className='desText'>We offer advanced RPA solutions to automate and optimize your business processes for increased efficiency and productivity.</span>
</div>
     
    </>
  );
};

export default WebDesign;
