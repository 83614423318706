import React, { useState } from 'react'
import "./Services.scss"
import Web from '../Web'
import MobileScreen from '../MobileScreen'
import CRM from "../CRM"
import Automation from '../Automation'
import Web2 from '../Web2'
import WebDesign from "../WebDesign" 

const Services = () => {

const data = [
  "Web App Development",
  "Mobile App Development",
  "AI / Machine Learning",
  "ERP/POS/CRM/Billing",
  "Automation",
  "Web Scraping"

]

const [service, setService] = useState("Web App Development")

  return (
    <section className='service_section'>

      <div className='service'>
     
      <h1 className='service_tittle'>Our Services</h1>
     
     <div className='service_left'>
      <ul className='service_list'>
        {
          data.map(function(item){
            return  <li Key={item} className='service_item' data-text={item} onClick={()=>setService(item)}> {item}</li>
          })
        }
        
      </ul>
     
     </div>

     <div className="service_right">
  {service === "Web App Development" ? (
    <Web2 />
  ) : service === "Mobile App Development" ? (
    <MobileScreen />
  ) : service === "AI / Machine Learning" ? (
    <Automation/> 
  ) : service === "ERP/POS/CRM/Billing" ? (
    <CRM />
  ) : service === "Automation" ? (<WebDesign/>):
  <Web/>
  }
</div>


    </div>
      
    

    </section>
  )
}

export default Services