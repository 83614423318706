import React, { Suspense } from 'react'
import "./Header.scss"
import Navbar from '../Navbar/Navbar'
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Sphere, MeshDistortMaterial } from "@react-three/drei";

const Header = () => {
  return (
    
   <section className='header_section'>
    <Navbar/>

    <div className='header'>
     <div className='header_left'>
     <span className='header_heading'>Develop Your Ideas Through Us</span>
     
     <div className='header_vision'>
      <img className='header_line' src='/img/line.png'></img>
      <span className='header_subheading'>You Have A Vision.</span>
     </div>
     
     <span className='header_subheading2'>We Have A Team To Get You There</span>
     <a href="#contact" className='link'> <button className='header_button'>Contact us</button></a>
     </div>

     <div className="header_right">
     <Canvas>
            <Suspense fallback={null}>
              <OrbitControls enableZoom={false} />
              <ambientLight intensity={1} />
              <directionalLight position={[3, 2, 1]} />
              <Sphere args={[1, 100, 200]} scale={2.5}>
                <MeshDistortMaterial
                  color="#3d1c56"
                  attach="material"
                  distort={0.5}
                  speed={2}
                />
              </Sphere>
            </Suspense>
          </Canvas>
      <img className='header_image' src='/img/headerimg.png'></img>
     </div>

    </div>
    </section>
  )
}

export default Header