/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 mac.gltf --transform
Author: MANUGR (https://sketchfab.com/MANUGR)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/mac-laptop-8018f263bfe14dd68584cbff81a68a23
Title: MAC LAPTOP
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('./img/mac-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes['portatil_Material_#27_0'].geometry} material={materials.Material_27} position={[-24.577, 85.648, 78.074]} rotation={[-Math.PI / 2, 0, -Math.PI]} scale={[0.326, 0.228, 0.228]} />
      <mesh geometry={nodes['Box001_Material_#28_0'].geometry} material={materials.Material_28} position={[-24.549, 85.874, 81.581]} rotation={[-Math.PI / 2, 0, Math.PI]} scale={[0.608, 0.412, 0.265]} />
    </group>
  )
}

useGLTF.preload('/mac-transformed.glb')
