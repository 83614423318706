import React from 'react'
import "./Why.scss"
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import GroupsIcon from '@mui/icons-material/Groups';




const Why = () => {
  return (
    <>
    

    <section className='why_section'>
      
  
      <div className='why'>
     
      <h1 className='why_tittle'>Why Choose us</h1>
     
     <div className='why_container'>
     
     <div className='why_items'>
        <span className='why_icons price'><CurrencyRupeeIcon className='item_icon'/></span>
        <span className="item_text">Affordable Price </span>
     </div>

     <div className='why_items '>
        <span className='why_icons design'><MobileFriendlyIcon className='item_icon'/></span>
        <span className="item_text">Responsive design </span>
     </div>

     <div className='why_items '>
        <span className='why_icons delivery'><ElectricBoltIcon className='item_icon'/></span>
        <span className="item_text">On-time Delivery Process </span>
     </div>

     <div className='why_items '>
        <span className='why_icons support'><SupportAgentIcon className='item_icon'/></span>
        <span className="item_text">24/7 Support </span>
     </div>

     <div className='why_items '>
        <span className='why_icons eye'><RemoveRedEyeIcon className='item_icon'/></span>
        <span className="item_text">Transparency</span>
     </div>

     <div className='why_items '>
        <span className='why_icons team'><GroupsIcon className='item_icon'/></span>
        <span className="item_text">Dedicated Team </span>
     </div>

     </div>

    </div>
      
      </section>
      </>
  )
}

export default Why